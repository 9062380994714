<template>
    <div class="container-fluid h-100">
      <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
        <!-- SVG icon with padding -->
        <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" class="position-absolute top-5 start-0 mt-3 ms-3" style="width: 70px; height: 70px;" alt="Icon">
      </div>
      <!-- Different code for mobile screens -->
      <div class="position-absolute top-0 mt-5 end-0 d-lg-none">
        <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" style="width: 70px; height: 70px;" alt="Icon">
      </div>

      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-4 d-flex flex-column position-relative align-items-center justify-content-center">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-12 text-center pt-0">
                <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486169/portrait_hgi0id.svg" class="img-fluid" alt="Icon">
              </div>
            </div>
          </div>
          <h1 class="text-white mt-3 text-center">Verifica tu Identidad</h1>
          <p class="text-white">PASO 1</p>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-12 text-center pt-0">
                <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486171/personHoldingId_migid0.svg" class="img-fluid" alt="Icon">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Different code for larger screens -->
      <div class="card position-absolute bottom-0 start-50 translate-middle-x" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5;">
        <div class="card-body d-flex flex-column align-items-center justify-content-center">
          <div class="input-group d-none d-lg-block" style="width: 70%; height: 55px; display: flex; align-items: center;">
            <p style="color: #F5F6F6;">Tómate una fotografía sosteniendo tu INE como se encuentra en la imágen de arriba.</p>
          </div>

          <div class="input-group d-lg-none mt-4 mb-4" style="width: 70%; height: 60px; display: flex; align-items: center;">
            <p style="color: #F5F6F6; text-align: center;">Tómate una fotografía sosteniendo tu INE como se encuentra en la imágen a continuación.</p>
          </div>
          
          <div style="width: 100%; display: flex; justify-content: center;">
            <label class="btn mt-3 d-flex justify-content-center align-items-center" 
                  style="background-color: #F5F6F6; color: #291CB5; width: 40%; height: 50px; margin-right: 10px;">
              <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin: auto 0;">{{ selectedFileName || 'Elegir archivo' }}</p>
              <input type="file" @change="handleFileChange" style="display: none;">
            </label>
            
  
            <label :disabled="isLoading" @click="uploadPicture" class="btn mt-3 shadow-label d-flex justify-content-center align-items-center" style="background-color: #4DFFE7; color: #3E2EF2; width: 30%; height: 50px;">
              <span v-if="!isLoading">{{ $t('buttons.submit') }}</span>
              <span v-else>
                <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CryptoJS from 'crypto-js';
  import { JSEncrypt } from 'jsencrypt'
  import Swal from 'sweetalert2';

  export default {
    props: {
        documentUrl: {
            type: String,
            required: true
        },
        publicKey: {
          type: String,
          required: true
        },
        isLoading: Boolean,
    },
    data() {
      return {
        file: null,
        selectedFileName: null,
      };
    },
    methods: {
      handleFileChange(event) {
        // Handle file change event and store the selected file
        const file = event.target.files[0];
        if (!file) return; // No file selected
        if (!file.type.startsWith('image/')) {
          Swal.fire({
            icon: 'error',
            title: 'Tipo de archivo invalido',
            text: 'Por favor seleccione una imagen',
          });
          return;
        }
        // Check file size (in bytes)
        const maxSize = 1 * 1024 * 1024; // 1MB
        if (file.size > maxSize) {
          Swal.fire({
            icon: 'error',
            title: 'Archivo demasiado grande',
            text: 'Por favor seleccione una imagen de menos de 1MB',
          });
          return;
        }
        this.file = file;
        this.selectedFileName = this.file.name;
        // You can perform validation on the file if needed
        // For example, check file type, size, etc.
      },
      uploadPicture(){
        if (!this.file) {
          Swal.fire({
            icon: 'error',
            title: 'Archivo faltante',
            text: 'Por favor seleccione una imagen',
          });
          return; // No file selected
        }
        const image = this.file;
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          const fileData = reader.result;
          // Encrypt the signature data with the public key
          this.encryptWithPublicKey(fileData, this.publicKey)
            .then(encryptedSignatureData => {
              // Emit the encrypted signature data to the parent component
              this.$emit('upload-picture', encryptedSignatureData);
            })
            .catch(error => {
              console.error('Encryption error:', error);
            });
        }
      },
      async encryptWithPublicKey(data, publicKey) {
        try {
            // Step 1: Generate a random AES key
            const aesKey = CryptoJS.lib.WordArray.random(32); // 32 bytes (256 bits) for AES-256
            const iv = CryptoJS.lib.WordArray.random(16);
            
            console.log(aesKey.toString(CryptoJS.enc.Base64))
            // Step 2: Encrypt the AES key with the public key
            const encryptor = new JSEncrypt();
            encryptor.setPublicKey(publicKey);
            const encryptedAesKey = encryptor.encrypt(aesKey.toString(CryptoJS.enc.Base64));
            const encryptedAesIV = encryptor.encrypt(iv.toString(CryptoJS.enc.Base64));
            console.log(encryptedAesKey)
            // Step 3: Encrypt the data with the AES key
            var cipher = CryptoJS.AES.encrypt(data, aesKey, {iv: iv, padding: CryptoJS.pad.NoPadding});
            // const cipher = CryptoJS.AES.encrypt(data, aesKey, {
            //     mode: CryptoJS.mode.CBC,
            //     padding: CryptoJS.pad.Pkcs7
            // });
            const encryptedString = cipher.toString();

            return {
                encryptedAesKey,
                encryptedAesIV,
                encryptedData: encryptedString
            };
        } catch (error) {
            throw new Error('Encryption error: ' + error.message);
        }
      }
    }
  }
  </script>
  <style>
.custom-file-upload {
  display: inline-block;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

.custom-file-upload span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.shadow-label {
  box-shadow: 0 8px 16px rgba(77, 255, 231, 0.4);
}
</style>
  