<template>
  <div style="background-color: #3E2EF2; height: 90vh;">
    <ErrorComponent v-if="error"/>
    <EmailForm v-else-if="!emailVerified" @email-verified="verifyEmail" :isLoading="isLoading" :name="name"/>
    <TermsAndConditions v-else-if="!termsAcepted" @acept-terms="aceptTerms" :isLoading="isLoading"/>
    <PictureUploadForm v-else-if="showPictureUploadForm" @upload-picture="uploadPicture" :documentUrl="documentUrl" :public-key="this.publicKey" :isLoading="isLoading"/>
    <PdfDocument v-else-if="documentLoadingSuccess && !documentSigned" :documentUrl="documentUrl" @continue-form="continueForm" :public-key="this.publicKey" :isLoading="isLoading"/>
    <WaitingForValidation v-else-if="showWaitingForValidation"/>
    <ValidatedComponent v-else-if="showVerified" :name="name" :date="date" :id="id"/>
    <ErrorComponent v-else/>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

import api from '../../interceptors/axiosInterceptos'; 
import EmailForm from '../../components/signing/EmailForm.vue';
import PdfDocument from '../../components/signing/PdfDocument.vue';
import PictureUploadForm from '../../components/signing/PictureUploadForm.vue';
import ErrorComponent from '../../components/signing/ErrorComponent.vue';
import WaitingForValidation from '../../components/signing/WaitingForValidation.vue';
import ValidatedComponent from '../../components/signing/ValidatedComponent.vue';
import TermsAndConditions from '../../components/signing/TermsAndConditions.vue';

export default {
  components: {
    EmailForm,
    PdfDocument,
    PictureUploadForm,
    WaitingForValidation,
    ErrorComponent,
    ValidatedComponent,
    TermsAndConditions
  },
  data() {
    return {
      name: '',
      date: '09/91/2022 21:46',
      id: '61da1a0287729d352bf7bef1',
      error: false,
      isLoading: false,
      emailToVerify: '',
      emailVerified: false,
      termsAcepted: false,
      publicKey: null,
      fileName: null,
      documentUrl: null,
      documentLoadingSuccess: false,
      showPictureUploadForm: false,
      showWaitingForValidation: false,
      
      documentSigned: false,
      showValidated: false,
      showVerified: false,
      latitude: null,
      longitude: null
    };
  },
  async mounted() {
    await this.getName();
    await this.getD();
  },
  watch: {
    async showWaitingForValidation(newValue){
      if(newValue && !this.documentSigned){
        try {
          const id = this.fileName;
          const response = await api.post(`${process.env.API_URL}/signing/sign/${id}`, {
            latitude: this.latitude,
            longitude: this.longitude
          });
          if(response.status == 200){
            Swal.fire({
              icon: 'success',
              title: 'Firma de documento',
              text: 'El documento se firmo exitosamente',
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Firma de documento',
              text: 'El documento se firmo incorrectamente',
            })
            this.isLoading = false;
          }
        } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error,
        })
        // Handle authentication error (e.g., display error message)
        this.isLoading = false;
      }
      }
    }
  },
  created() {
    
        // Retrieve the reset token from the route parameters
    this.fileName = this.$route.params.token;
  },
  methods: {
    async aceptTerms(){
      this.termsAcepted = true;
      this.showPictureUploadForm = true;
    },
    async getName(){
      const id = this.fileName;
      this.isLoading = true;
      try {
        const response = await api.get(`${process.env.API_URL}/signing/name/${id}`, {
        });
        if(response.status == 200){
          this.name = response.data.name;
          this.isLoading = false;
        } else {
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    promptLocationPermission() {
        Swal.fire({
            icon: 'warning',
            title: 'Compartenos tu ubicación.',
            text: "Al permitirnos que utilicemos la ubicación actual, cumplimos con el marco regulatorio aplicable, por favor accede a la configuracion de tu navegador para permitirnos conocer tu ubicacion",
        });
    },
    async verifyEmail(email) {
      try {
        if ("geolocation" in navigator) {
            // Fetch the user's current position
            navigator.geolocation.getCurrentPosition(
                position => {
                    // Extract latitude and longitude from the position object
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    
                    // You may want to store these values in your component's data for later use
                    this.latitude = latitude;
                    this.longitude = longitude;
                },
                error => {
                  // Handle errors if the user's location couldn't be fetched
                
                  this.promptLocationPermission();
                  return;
                }
            );
            
        } else {
          // Geolocation is not supported by the browser
          
          this.promptLocationPermission();
          return;
        }
      
        const id = this.fileName;
        this.isLoading = true;
        const response = await api.post(`${process.env.API_URL}/signing/state/${id}`, {
          email: email,
        });
        if(response.status == 200){
          this.emailVerified = true;
          this.termsAcepted = response.data.termsAcepted;
          this.showPictureUploadForm = response.data.showPictureUploadForm;
          this.publicKey = response.data.publicKey;
          this.emailVerified = response.data.emailVerified;
          this.showWaitingForValidation = response.data.showWaitingForValidation;
          this.showVerified = response.data.showVerified;
          this.documentSigned = response.data.documentSigned;
          this.showValidated = response.data.showValidated;
          this.isLoading = false;
        } else {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: "Verifica tu correo",
            })
            this.isLoading = false;
          }
        this.isLoading = false;
      } catch (error) {
        if(error.response.status == 400){
          Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: "El documento no existe o expiro",
            })
        }
        else if(error.response.status == 401){
          Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: "Verifica tu correo",
            })
        }else{
          this.error = true;
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Ocurrio un error interno por favor contacta a tu asesor",
          })
        }
        // Handle authentication error (e.g., display error message)
        this.isLoading = false;
      }
      
    },
    async getD(){
      const id = this.fileName;
      const response = await api.get(`${process.env.API_URL}/signing/${id}`, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      }).then(response => {
        // Set the document content received from the backend
        this.documentUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        this.documentLoadingSuccess = true;
          // Check if geolocation is supported by the browser
        if ("geolocation" in navigator) {
            // Fetch the user's current position
            navigator.geolocation.getCurrentPosition(
                position => {
                    // Extract latitude and longitude from the position object
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    
                    // You may want to store these values in your component's data for later use
                    this.latitude = latitude;
                    this.longitude = longitude;
                },
                error => {
                  // Handle errors if the user's location couldn't be fetched
                
                  this.promptLocationPermission();
                }
            );
            // Listen for changes in the user's permission settings
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
              permissionStatus.onchange = () => {
                  if (permissionStatus.state === 'granted') {
                      // Permission has been granted
                      
                      // Now you can fetch the user's location again if needed
                  } else {
                      // Permission has been denied or revoked
                      
                      this.promptLocationPermission();
                  }
              };
          });
        } else {
          // Geolocation is not supported by the browser
          
          this.promptLocationPermission();
        }
      })
      .catch(error => {
        console.error('Error fetching document:', error);
        this.documentLoadingSuccess = false;
        this.error = true;
        // Handle error if needed
      });
    },
    async continueForm(encryptedSignatureData){
      const id = this.fileName;
      this.isLoading = true;
      try {
        const response = await api.post(`${process.env.API_URL}/signing/upload/signature/${id}`, {
          signature: encryptedSignatureData.encryptedData,
          aesKey: encryptedSignatureData.encryptedAesKey,
          aesIV: encryptedSignatureData.encryptedAesIV
        });
        if(response.status == 200){
          this.documentLoadingSuccess = false;
          this.showWaitingForValidation = true;
          this.isLoading = false;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.statusText,
          })
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error,
        })
        this.isLoading = false;
      }
    },
    async uploadPicture(encryptedSignatureData) {
      const id = this.fileName;
      this.isLoading = true;
      
      try {
        
        const response = await api.post(`${process.env.API_URL}/signing/upload/id/${id}`, {
          signature: encryptedSignatureData.encryptedData,
          aesKey: encryptedSignatureData.encryptedAesKey,
          aesIV: encryptedSignatureData.encryptedAesIV
        });
        if(response.status == 200){
          await this.getD();
          this.documentLoadingSuccess = true;
          this.showPictureUploadForm = false;
          this.isLoading = false;
          
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: response.statusText,
          })
          this.isLoading = false;
        }
        this.isLoading = false;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error,
        })
        this.isLoading = false;
      }

    }
  }
}
</script>

<style scoped>
</style>
