<template>
  <div class="container-fluid h-100">
    <!-- position-fixed  -->
    <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
      <!-- SVG icon with padding -->
      <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" class="position-absolute top-5 start-0 mt-3 ms-3" style="width: 70px; height: 70px;" alt="Icon">
    </div>
    <!-- Different code for mobile screens -->
    <div class="position-absolute top-5 mt-5 start-50 translate-middle d-lg-none">
      <img class="mt-5" src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" style="width: 70px; height: 70px;" alt="Icon">
    </div>
    
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-3 position-relative">
        <!-- Icon image with padding -->
        <!-- <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486167/hand_gie3p6.svg" class="" style="width: 80px; height: 80px;" alt="Icon"> -->
        
        
        <div class="d-lg-none" style="height: 120px;"></div>
      </div>
    </div>
    <div v-show="!isDocumentFullyScrolled">
      <div class="card position-fixed bottom-0 start-50 translate-middle-x" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 600px; background-color: #291CB5;">
        <div class="card-body d-flex justify-content-center mt-3">
          <div class="input-group mb-3" style="width: 90%;  display: flex; align-items: center; justify-content: center;">
            <p class="text-white" style="font-size: 12px; text-align: center;">¡ME DESCONTARÁN SEMANAS DE COTIZACIÓN.(SI PERTENEZCO A LA LEY 73, ESTO AFECTARÁ DIRECTAMENTE EN MI PENSIÓN).</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">NO PERDERÉ LA INE DURANTE EL TRÁMITE O AVISARÉ INMEDIATAMENTE EN CASO DE QUE PASÉ Y REPONDRÉ LA IDENTIFICACIÓN.</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">DEBO DE TENER MI EXPEDIENTE BIOMETRICO ACTUALIZADO EN LA AFORE</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">EL TRÁMITE PODRÁ TARDAR DE 50 A 60 DÍAS NATURALES, SIEMPRE Y CUANDO TODOS LOS DOCUMENTOS ESTÉN EN TIEMPO Y FORMA.</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">DE LA CANTIDAD A RETIRAR PAGARÉ EL 33% A LA EMPRESA.</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">EN CASO DE CANCELAR EL TRÁMITE O DARME DE ALTA AL IMSS HAY UNA PENALIZACIÓN DE $3,000.</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">EN CASO DE NEGARME A PAGAR EL 33%, AFIE ESTÁ EN TODO SU DERECHO DE HACER EFECTIVO EL PAGARÉ</p>
              <p class="text-white" style="font-size: 12px; text-align: center;">BAJO PROTESTA DE DECIR VERDAD, MANIFIESTO QUE LA INFORMACIÓN, DATOS Y DOCUMENTOS ENVIADOS PARA CON SON VERÍDICOS y AUTORIZO A “AFIE CONSULTORES”, S.A. DE C.V., PARA QUE REALICE EXPEDIENTE DE IDENTIFICACION, INTEGRE, CORROBORE DICHA INFORMACIÓN, Y CONSERVE MI EXPEDIENTE PARA EN CASO DE SER NECESARIO, PROPORCIONE MI INFORMACIÓN Y/O DATOS Y/O DOCUMENTOS A CUALQUIER AUTORIDAD ADMINISTRATIVA O JUDICIAL.</p>
              <p style="color: #F5F6F6; text-align: center">HAZ CLICK PARA ACEPTAR LOS SIGUIENTES TERMINOS Y CONDICIONES.</p>
              <div style="width: 100%; display: flex; justify-content: center;">
                <label :disabled="isLoading" @click="aceptTerms" class="btn mt-3 shadow-label d-flex justify-content-center align-items-center" style="background-color: #4DFFE7; color: #3E2EF2; width: 30%; height: 50px;">
                  <span v-if="!isLoading">ACEPTAR</span>
                  <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                  </span>
                </label>
              </div>
              
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      isLoading: Boolean,
    },
    data() {
      return {
        
      };
    },
    methods: {
      aceptTerms() {
        // Emit an event with the entered email
        this.$emit('acept-terms');
      }
    }
  }
  </script>
  
  <style>
.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #4DFFE7;
    opacity: 1; /* Firefox */
    text-align: center; /* Center the placeholder text */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4DFFE7;
    text-align: center; /* Center the placeholder text */
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #4DFFE7;
    text-align: center; /* Center the placeholder text */
}
.shadow-label {
  box-shadow: 0 8px 16px rgba(77, 255, 231, 0.4);
}
</style>