<template>
    <div ref="container-fluid h-100" class="pdf-container">
      <div class="arrow-left" @click="goToPreviousPage">&#x2190;</div>
      <span class="page-text-left">página anterior</span>
      <div class="arrow-right" @click="goToNextPage">&#x2192;</div>
      <span class="page-text-right" >página siguiente</span>
      
      

      <div v-show="!isDocumentFullyScrolled">
        <div class="container-fluid mt-3">
          <div class="row justify-content-center">
            <div class="col-12 text-center pt-0">
              <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486170/book_g3wgmz.svg" class="img-fluid" alt="Icon">
              <h1 class="text-white mt-4">Lee el documento</h1>
              <p class="text-white">PASO 2</p>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isDocumentFullyScrolled">
        <div class="container-fluid mt-3">
          <div class="row justify-content-center">
            <div class="col-12 text-center pt-0">
              <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486168/pencil_ecgnht.svg" class="img-fluid" alt="Icon">
              <h1 class="text-white mt-4">Firma el documento</h1>
              <p class="text-white">PASO 3</p>
            </div>
          </div>
        </div>
      </div>
      <canvas ref="canvas" class="pdf-canvas"></canvas>
      <div style="height: 150px;"></div>
    </div>
        

    <!-- Step 2 reading document -->
    <div v-show="!isDocumentFullyScrolled">
      <div class="card position-fixed bottom-0 start-50 translate-middle-x" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5;">
        <div class="card-body d-flex justify-content-center mt-3">
          <div class="input-group mb-3 d-none d-lg-block" style="width: 70%; height: 60px; display: flex; align-items: center;">
            <p style="color: #F5F6F6; text-align: center;">Este es el documento a firmar, por favor revisa todos los datos y avanza hasta el final del documento para firmar.</p>
          </div>
          <div class="input-group mb-5 d-lg-none" style="width: 70%; height: 60px; display: flex; align-items: center;">
            <p style="color: #F5F6F6; text-align: center;">Este es el documento a firmar, por favor revisa todos los datos y avanza hasta el final del documento para firmar.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3 signing document -->
    <div v-show="isDocumentFullyScrolled">
      <div class="card position-fixed bottom-0 start-50 translate-middle-x" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: white;">
        <div class="card-body d-flex justify-content-center mt-3">
          <div v-show="isDocumentFullyScrolled" style="width: 100%;">
            
            <!-- Different code for larger screens -->
            <div class="canvas-container d-none d-lg-block" style="position: relative; background-color: #E5E5E5; width: 400px; margin: 0 auto; border-radius: 15px;">
              <canvas ref="signatureCanvasLarge" width="400" height="200" 
                  @touchstart="handleTouchStart"
                  @touchmove="handleTouchMove"
                  @touchend="handleTouchEnd">
              </canvas>
              <p v-if="isCanvasEmpty" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">firma aquí</p>
            </div>
            
            <!-- Different code for mobile screens -->
            <div class="canvas-container d-lg-none" style="position: relative; background-color: #E5E5E5; width: 350px; margin: 0 auto; border-radius: 15px;">
              <canvas ref="signatureCanvasSmall" width="350" height="200" 
                  @touchstart="handleTouchStart"
                  @touchmove="handleTouchMove"
                  @touchend="handleTouchEnd">
              </canvas>
              <p v-if="isCanvasEmpty" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">firma aquí</p>
            </div>
            
            
            
            <div class="mt-5" style="height: 60px;"></div>
            
            <label @click="clearSignature" class="btn label-clear mt-5" style="background-color: rgba(77, 255, 231, 0.31); color: #3E2EF2;">
                Borrar
            </label>

            <label :disabled="isLoading" @click="continueToForm" class="btn label-next mt-5" style="background-color: #4DFFE7; color: #3E2EF2;">
                <span v-if="!isLoading">{{ $t('buttons.next') }}</span>
                <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
            </label>
              
          </div>
        </div>
      </div>
    </div>
    
    
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  import CryptoJS from 'crypto-js';
  import { JSEncrypt } from 'jsencrypt'

  import { getDocument, GlobalWorkerOptions, PDFWorker } from 'pdfjs-dist';
  
  export default {
    props: {
        documentUrl: {
            type: String,
            required: true
        },
        publicKey: {
          type: String,
          required: true
        },
        isLoading: Boolean,
    },
    data() {
      return {
        isCanvasEmpty: true,
        isDocumentFullyScrolled: false,
        currentPage: 1,
        totalPages: 0,
        scale: .6,
        pdfInstance: null,
      };
    },
    mounted() {
      this.renderPDF();
      // Add a scroll event listener to detect scrolling
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // Remove touch event listeners when component is unmounted
        if (window.innerWidth> 600) {
          this.$refs.signatureCanvasLarge.removeEventListener('touchstart', this.preventDefaultTouchScroll);
          this.$refs.signatureCanvasLarge.removeEventListener('touchmove', this.preventDefaultTouchScroll);
        } else{
          this.$refs.signatureCanvasSmall.removeEventListener('touchstart', this.preventDefaultTouchScroll);
          this.$refs.signatureCanvasSmall.removeEventListener('touchmove', this.preventDefaultTouchScroll);
        }
        // Remove the scroll event listener when the component is unmounted
        window.removeEventListener('scroll', this.goToNextPage);
      },
    watch: {
      isDocumentFullyScrolled(newValue) {
        if (newValue) {
          // When isDocumentFullyScrolled becomes true, set up the signature functionality
          this.setupSignature();
          // Prevent touch events from triggering scrolling
          try {
            if (window.innerWidth> 600) {
              this.$refs.signatureCanvasLarge.addEventListener('touchstart', this.preventDefaultTouchScroll, { passive: false });
              this.$refs.signatureCanvasLarge.addEventListener('touchmove', this.preventDefaultTouchScroll, { passive: false });
            }else {
              this.$refs.signatureCanvasSmall.addEventListener('touchstart', this.preventDefaultTouchScroll, { passive: false });
              this.$refs.signatureCanvasSmall.addEventListener('touchmove', this.preventDefaultTouchScroll, { passive: false });
            }
          } catch (error) {
          }
        }
      },
    },
    methods: {
      calculateScaleFactor(page) {
        const devicePixelRatio = window.devicePixelRatio || 1;
        const screenWidth = window.innerWidth;
        const pageWidth = page.getViewport({ scale: 1 }).width;
        
        let scale;
        console.log(pageWidth)
        console.log(screenWidth)
        if (screenWidth < 600) {
            // For mobile devices
            scale = 0.6;
        } else if (pageWidth > screenWidth) {
            // If the document width is larger than the screen width, scale to fit
            scale = pageWidth/ screenWidth;
        } else {
            // For desktop devices
            scale = 1.5;
        }
        
        return scale;
      },
      async renderPDF() {
        const container = this.$refs.container;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');

        // pdfjsLib.GlobalWorkerOptions.workerSrc = 'path/to/pdf.worker.js';
        // GlobalWorkerOptions.workerSrc = URL.createObjectURL(
        //   new Blob([PDFWorker.getWorkerSrc()], { type: 'application/javascript' })
        // );
        GlobalWorkerOptions.workerSrc = window.location.origin + '/js/pdf.worker.js';
        // GlobalWorkerOptions.workerSrc ="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/build/pdf.worker.min.js";
        const loadingTask = getDocument(this.documentUrl);

        loadingTask.promise.then(pdf => {
          this.pdfInstance = pdf;
          this.totalPages = pdf.numPages;

          pdf.getPage(this.currentPage).then(page => {
            let scale = this.calculateScaleFactor(page);

            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
          });
        });
      },  
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
          this.renderPage(this.currentPage);
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
          this.renderPage(this.currentPage);
        }
        if (this.currentPage == this.totalPages) {
          this.isDocumentFullyScrolled = true;
        }
      },
      renderPage(pageNumber) {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        const loadingTask = getDocument(this.documentUrl);
  
        loadingTask.promise.then(pdf => {
          this.pdfInstance = pdf;
          this.totalPages = pdf.numPages;
  
          pdf.getPage(pageNumber).then(page => {
            let scale = this.calculateScaleFactor(page);

            const viewport = page.getViewport({ scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
  
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
          });
        });
      },
      setupSignature() {
        let signatureCanvas;
        if (window.innerWidth> 600) {
          signatureCanvas = this.$refs.signatureCanvasLarge;
        } else{
          signatureCanvas = this.$refs.signatureCanvasSmall;
        }
        const signatureCtx = signatureCanvas.getContext('2d');
        
        let isDrawing = false;
        let lastX = 0;
        let lastY = 0;
        
        signatureCanvas.addEventListener('mousedown', (e) => {
          isDrawing = true;
          [lastX, lastY] = [e.offsetX, e.offsetY];
        });
        
        signatureCanvas.addEventListener('mousemove', (e) => {
          if (!isDrawing) return;
          this.isCanvasEmpty = false;
          signatureCtx.beginPath();
          signatureCtx.moveTo(lastX, lastY);
          signatureCtx.lineTo(e.offsetX, e.offsetY);
          signatureCtx.stroke();
          [lastX, lastY] = [e.offsetX, e.offsetY];
        });
        
        signatureCanvas.addEventListener('mouseup', () => isDrawing = false);
        signatureCanvas.addEventListener('mouseout', () => isDrawing = false);
      },
      clearSignature() {
        this.isCanvasEmpty = true;
        let signatureCanvas;
        if (window.innerWidth> 600) {
          signatureCanvas = this.$refs.signatureCanvasLarge;
        } else{
          signatureCanvas = this.$refs.signatureCanvasSmall;
        }
        const signatureCtx = signatureCanvas.getContext('2d');
        signatureCtx.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);
      },
        preventDefaultTouchScroll(event) {
          // Prevent default behavior of touch events to prevent scrolling
          event.preventDefault();
        },
        handleTouchStart(event) {
          this.isCanvasEmpty = false;
          // Prevent default touch behavior to prevent scrolling
          event.preventDefault();
  
          // Get touch coordinates relative to the canvas
          let signatureCanvas;
          if (window.innerWidth> 600) {
            signatureCanvas = this.$refs.signatureCanvasLarge;
          } else{
            signatureCanvas = this.$refs.signatureCanvasSmall;
          }
          const rect = signatureCanvas.getBoundingClientRect();
          const x = event.touches[0].clientX - rect.left;
          const y = event.touches[0].clientY - rect.top;
  
          // Start drawing at touch coordinates
          this.isDrawing = true;
          this.lastX = x;
          this.lastY = y;
        },
        handleTouchMove(event) {
          // Prevent default touch behavior to prevent scrolling
          event.preventDefault();
  
          if (this.isDrawing) {
            // Get touch coordinates relative to the canvas
            let signatureCanvas;
            if (window.innerWidth> 600) {
              signatureCanvas = this.$refs.signatureCanvasLarge;
            } else{
              signatureCanvas = this.$refs.signatureCanvasSmall;
            }
            const rect = signatureCanvas.getBoundingClientRect();
            const x = event.touches[0].clientX - rect.left;
            const y = event.touches[0].clientY - rect.top;
  
            // Draw line from last point to current point
            const ctx = signatureCanvas.getContext('2d');
            ctx.beginPath();
            ctx.moveTo(this.lastX, this.lastY);
            ctx.lineTo(x, y);
            ctx.stroke();
  
            // Update last coordinates
            this.lastX = x;
            this.lastY = y;
          }
        },
        handleTouchEnd(event) {
          // Prevent default touch behavior to prevent scrolling
          event.preventDefault();
  
          // Stop drawing
          this.isDrawing = false;
        },
        handleScroll(){
          if (this.currentPage == this.totalPages) {
            this.isDocumentFullyScrolled = true;
          }
        },
        continueToForm(){
          if (this.isCanvasEmpty) {
            Swal.fire({
              icon: 'error',
              title: 'Sin Firma de documento',
              text: 'Por favor firme el documento para continuar',
            })
            return;
          };
          // Get the signature canvas element
          let signatureCanvas;
          if (window.innerWidth> 600) {
            signatureCanvas = this.$refs.signatureCanvasLarge;
          } else{
            signatureCanvas = this.$refs.signatureCanvasSmall;
          }

          // Get the image data URL from the canvas
          const signatureImageDataURL = signatureCanvas.toDataURL();

          // Encrypt the signature data with the public key
          this.encryptWithPublicKey(signatureImageDataURL, this.publicKey)
            .then(encryptedSignatureData => {
              // Emit the encrypted signature data to the parent component
              this.$emit('continue-form', encryptedSignatureData);
            })
            .catch(error => {
              console.error('Encryption error:', error);
            });
        },
        async encryptWithPublicKey(data, publicKey) {
          try {
              // Step 1: Generate a random AES key
              const aesKey = CryptoJS.lib.WordArray.random(32); // 32 bytes (256 bits) for AES-256
              const iv = CryptoJS.lib.WordArray.random(16);
              
              console.log(aesKey.toString(CryptoJS.enc.Base64))
              // Step 2: Encrypt the AES key with the public key
              const encryptor = new JSEncrypt();
              encryptor.setPublicKey(publicKey);
              const encryptedAesKey = encryptor.encrypt(aesKey.toString(CryptoJS.enc.Base64));
              const encryptedAesIV = encryptor.encrypt(iv.toString(CryptoJS.enc.Base64));
              console.log(encryptedAesKey)
              // Step 3: Encrypt the data with the AES key
              var cipher = CryptoJS.AES.encrypt(data, aesKey, {iv: iv, padding: CryptoJS.pad.NoPadding});
              // const cipher = CryptoJS.AES.encrypt(data, aesKey, {
              //     mode: CryptoJS.mode.CBC,
              //     padding: CryptoJS.pad.Pkcs7
              // });
              const encryptedString = cipher.toString();

              return {
                  encryptedAesKey,
                  encryptedAesIV,
                  encryptedData: encryptedString
              };
          } catch (error) {
              throw new Error('Encryption error: ' + error.message);
          }
      }
        // async encryptWithPublicKey(data, publicKey) {
        //   try {
        //     const aesKey = CryptoJS.PBKDF2('key', 'salt', { keySize: 256/32, iterations: 100 });
        //     // const aesKey = CryptoJS.lib.WordArray.random(16);
        //     const iv = CryptoJS.enc.Utf8.parse('a2xhcgAAAAAAAAAA');
        //     // const iv = CryptoJS.lib.WordArray.random(16);
        //     const encrypted = CryptoJS.AES.encrypt(data, aesKey, { iv: iv, mode: CryptoJS.mode.CBC });
        //     const encryptedData = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

        //     // New JSEncrypt object
        //     let encryptor = new JSEncrypt();
        //     console.log(publicKey);
        //     // Setting public key
        //     encryptor.setPublicKey(publicKey);
        //     // Encrypted data
            
        //     console.log(aesKey.toString(CryptoJS.enc.Base64));
        //     const encryptedAesKey = encryptor.encrypt(aesKey.toString(CryptoJS.enc.Base64));
        //     return { encryptedData, encryptedAesKey };

        //   } catch (error) {
        //     throw new Error('Encryption error: ' + error.message);
        //   }
        // },

    }
  }
  </script>
  
  <style scoped>
  
  </style>
  