<template>
  <div class="container-fluid h-100">
    <!-- position-fixed  -->
    <div class="top-5 start-0 mt-3 ms-3 d-none d-lg-block">
      <!-- SVG icon with padding -->
      <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" class="position-absolute top-5 start-0 mt-3 ms-3" style="width: 70px; height: 70px;" alt="Icon">
    </div>
    <!-- Different code for mobile screens -->
    <div class="position-absolute top-5 mt-5 start-50 translate-middle d-lg-none">
      <img class="mt-5" src="https://res.cloudinary.com/dvph93zsr/image/upload/v1710794277/logo_saz6bb.svg" style="width: 70px; height: 70px;" alt="Icon">
    </div>
    
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-md-3 d-flex flex-column position-relative align-items-center justify-content-center">
        <!-- Icon image with padding -->
        <img src="https://res.cloudinary.com/dvph93zsr/image/upload/v1711486167/hand_gie3p6.svg" class="" style="width: 80px; height: 80px;" alt="Icon">
        
        <p class="text-white fs-6">¡Hola {{this.name}}!</p>
        <p class="text-white fs-6 text-center">Te damos la bienvenida al proceso de autenticación y aprobación de documentos de AFIE consultores.</p>
        <p class="text-white fs-6">¿Comenzamos?</p>
        <div class="d-lg-none" style="height: 120px;"></div>
      </div>
    </div>
    <!-- Different code for mobile screens -->
    <div class="card position-absolute bottom-0 start-0 end-0 d-lg-none" style="border-top-left-radius: 40px; border-top-right-radius: 40px; background-color: #291CB5;">
      <div class="card-body d-flex flex-column align-items-center justify-content-center mt-5">
        <div class="input-group mb-3" style="width: 70%; height: 60px;">
          <input type="email" class="form-control text-center" id="inputGroupFile01" placeholder="tu@email.com" style="border-color: #4DFFE7; background-color: #291CB5; color: #4DFFE7; height: 100%;" v-model="email">
        </div>
        <button @click="checkEmail" class="btn shadow-label" style="background-color: #4DFFE7; color: #3E2EF2; font-weight: bold; width: 70%; height: 60px;">
          <span v-if="!isLoading">Comenzar</span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
        </button>
        <label class="fs-10 mb-2 mt-5" for="email" style="color: #F5F6F6;">Ingresa tu email por favor:</label>
      </div>
    </div>

    <!-- Different code for larger screens -->
    <div class="card position-absolute bottom-0 start-50 translate-middle-x d-none d-lg-block" style="border-top-left-radius: 40px; border-top-right-radius: 40px; width: 100%; max-width: 500px; background-color: #291CB5;">
      <div class="card-body d-flex flex-column align-items-center justify-content-center mt-3">
        <div class="input-group mb-3" style="width: 70%; height: 60px; display: flex; align-items: center;">
          <input type="email" class="form-control text-center" id="inputGroupFile01" placeholder="tu@email.com" style="border-color: #4DFFE7; background-color: #291CB5; color: #4DFFE7; height: 100%;" v-model="email">
          <label @click="checkEmail" class="input-group-text btn shadow-label d-flex justify-content-center align-items-center" style="background-color: #4DFFE7; color: #3E2EF2; height: 100%; font-weight: bold;" for="inputGroupFile01">
            <span v-if="!isLoading">Comenzar</span>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </label>
        </div>
        <label class="fs-10 mt-3 mb-3" for="email" style="color: #F5F6F6;">Ingresa tu email por favor:</label>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      isLoading: Boolean,
      name: String
    },
    data() {
      return {
        email: ''
      };
    },
    methods: {
      checkEmail() {
        // Emit an event with the entered email
        this.$emit('email-verified', this.email);
      }
    }
  }
  </script>
  
  <style scoped>

</style>